/* Font Family */
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital@1&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Top Contact */
.top-contact-wrapper {
  padding: 10px;
}

.top-contact-wrapper a {
  text-decoration: none;
  line-height: 2;
}

.top-contact-wrapper a:hover {
  color: #595959;
}
.top-contact-bar {
  text-align: center;
  width: 1px !important;
  color: #777777;
  padding-top: 5px;
}

/* NavBar */
.nav-items {
  font-size: 16px;
  font-weight: 400;
  padding: 12px;
}

.nav-items a {
  text-decoration: none;
  padding-left: 1.4rem !important;
  padding-right: 1.4rem !important;
  letter-spacing: 0.14rem;
  color: #1b1b1b;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
}

.nav-items a:hover {
  color: #0074c1;
}

.dropdown-item:active {
  color: #0074c1 !important;
  background-color: #fff !important;
}

.nav-link {
  margin: 0 !important;
  padding: 0 !important;
  color: #1b1b1b !important;
}

.active-tab {
  color: #0074c1 !important;
}

.active-item {
  background-color: #0074c1;
  color: #fff;
}

.active-product {
  background-color: #0074c1 !important;
}

.active-product a {
  color: #fff !important;
}

.active-product a:hover {
  color: #fff !important;
}

/* Header */
.header-wrapper {
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.logo {
  margin: 0 auto;
  width: 50%;
}
.organization-name {
  color: #595959;
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 0.143em;
  font-family: "Lato", sans-serif;
}

/* Contact  */
.contact-wrapper {
  /* margin-top: 3rem; */
  /* margin-bottom: 4rem; */
  font-family: "Lato", sans-serif;
}

.contact-seperator {
  margin-top: 1rem;
  color: #777777;
  margin-bottom: 2rem;
}
/* Footer */
.footer {
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  color: #777777;
  margin-bottom: 80px;
  font-size: 14px;
}

.social-network {
  margin-top: 40px;
  margin-bottom: 40px;
}

.copy-right {
  margin-top: 40px;
  margin-bottom: 40px;
}

.other-license {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  font: 14px;
}

/* Common */
pre {
  white-space: pre-wrap;
  font-family: "Montserrat", sans-serif !important;
}

#btn-back-to-top {
  position: fixed;
  bottom: 5px;
  right: 5px;
  background-color: #0074c1d2;
  border-color: #0074c1;
}

#btn-back-to-top:hover {
  background-color: #0074c1;
}

.readme-contents {
  color: #5e5e5e;
  font-size: 16px;
  word-wrap: break-word;
}

.loader {
  width: 10%;
  margin: 0 auto;
  margin-bottom: 5rem;
  margin-top: 5rem;
}

.main-content-wrapper {
  margin-left: 8px;
  margin-right: 8px;
}

.headings {
  font-size: 22px;
  text-transform: uppercase;
  line-height: 1.4rem;
  font-family: "Roboto", sans-serif;
}

.heading-seperator {
  border-color: #777777 !important;
  opacity: 0.75 !important;
  margin-bottom: 40px;
}

.sub-heading {
  font-size: 22px;
  line-height: 1.5rem;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

.contents {
  color: #5e5e5e;
  font-size: 16px;
}

.contents > a {
  text-decoration: none;
  color: #0074c1;
  font-size: 14px;
}

.cell {
  padding: 16px;
}

/* Home Page */
.home-img {
  background: url(https://sysenso-storage.s3.eu-west-1.amazonaws.com/images/webpage/AdobeStock_51723808.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 18rem;
  width: 90% !important;
  margin: 0 auto;
}

.core-skills-wrapper {
  margin-top: 15px;
  margin-bottom: 40px;
}
.core-skills-contents {
  padding: 16px;
}
.service-wrapper {
  margin-top: 15px;
  margin-bottom: 50px;
}
/* .service-contents {
  padding-top: 2rem;
} */
.list-items-wrapper {
  color: #5e5e5e;
  font-size: 16px;
  padding-left: 2rem;
}

.products-service-contents {
  padding: 30px;
  margin-top: 1.5rem;
}
.thumb-img {
  height: 100% !important;
  width: 90%;
  object-fit: contain;
}

.fixed-height {
  height: 300px;
  object-fit: contain;
}

.fixed-width {
  width: 100%;
  aspect-ratio: 22/15;
}

.image-gallery {
  background-color: #f8f9fa;
}

.image-gallery-wrapper {
  margin-top: 15px;
  margin-bottom: 60px;
}

.about-us-contents {
  padding: 2rem;
}

.input-height {
  height: 50px;
}
.contact-us-form {
  padding-left: 18px;
  padding-right: 18px;
}
.contact-us-contents {
  margin-top: 4rem;
  padding-left: 18px;
  padding-right: 18px;
}

.send-btn {
  background-color: #0074c1 !important;
  text-transform: uppercase;
  color: #fff !important;
  line-height: 1.4rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 3px;
}

/* Blogs */
.blog-link {
  color: #0074c1;
}
.blog-list {
  margin: 0 auto;
  margin-top: 4rem;
  padding: 30px;
}

.blog-cell {
  margin-top: 3rem;
}

.blog-seperator {
  margin-top: 3rem;
  color: #777777;
  margin-bottom: 2rem;
}

.continue-reading-blog {
  text-decoration: none;
  font-size: 16px;
  color: #0074c1;
}

.blog-heading {
  font-size: 24px;
  color: #353535;
  font-weight: 400;
}

.blog-sub-heading {
  font-size: 20px;
  color: #353535;
  font-weight: 400;
  margin-bottom: 2rem;
}

.blog-contents {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #5e5e5e;
  font-size: 17px;
}

.all-post {
  color: #0074c1;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
}

.blog-post {
  padding: 20px;
}

.blog-post-wrapper {
  border-right: 0px solid #d3d3d3;
}

.recent-post-heading {
  font-size: 18px;
  color: #353535;
  font-weight: 400;
  text-decoration: none;
}

.recent-post {
  text-decoration: none !important;
}

/* Products */

.product-wrapper {
  padding: 14px;
}

.product-title {
  color: #0074c1;
}

.product-list {
  margin-right: 30px;
  line-height: 2.6;
  font-size: 16px;
  color: #494949;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
  border-left-style: solid;
  border-left-color: #ededed;
  position: sticky;
  top: 50px;
}

.product-container {
  color: #494949;
  padding: 20px;
  box-shadow: 0px 1px 3px 3px rgb(0 0 0 / 15%);
  border-radius: 8px;
  font-family: "Lato", sans-serif;
  margin-bottom: 3rem;
}

.list-group {
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 0% !important;
}


.list-group-header {
  margin: 0;
  color: #0074c1;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
}

.product-list > ul {
  list-style-type: none;
}

.list-group-product {
  color: #696969;
  padding-left: 2rem !important;
}

.list-group-product a {
  font-size: 17px;
  line-height: 1.5rem;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  color: #696969;
  cursor: pointer;
  text-decoration: none;
}

.list-group-product > a:hover {
  color: #696969;
}

.list-group-menu {
  padding: 0 !important;
}

.detailed-desc {
  padding: 0 !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.accordion-button {
  color: #5e5e5e !important;
  font-size: 18px !important;
}

.download-btn {
  background-color: #0074c1 !important;
  color: #fff !important;
  padding: 8px !important;
  line-height: 1rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

.download-btn:active {
  background-color: #0074c1;
  box-shadow: 0 5px #666;
  transform: translateY(2px);
}

.download-btn-wrapper {
  margin-top: 2rem;
}

.required-field::after {
  padding: 3px;
  content: "*";
  color: red;
}

.form-wrapper {
  padding: 30px;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  font-family: Arial, Helvetica, sans-serif;
}

.free-products-form {
  background-color: #f7fafc;
  box-shadow: 0 0 0 1px #e3e8ee;
}

/* Commercial Products */
.product-content-wrapper {
  margin: 0 auto;
  padding: 14px;
}

.image-slider-wrapper {
  cursor: pointer;
}

.img-slider {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  width: 100%;
  height: 250px;
}

/* Pop Up Video Demo */
.pop-btn {
  background-color: #0074c1 !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
}

.popup-video {
  width: 100%;
  height: 300px;
}

.buy-btn-wrapper {
  margin-left: 3rem;
  font-family: "Roboto", sans-serif;
}

.buy-btn {
  background: #0074c1 !important;
  color: #fff !important;
  font-size: 12px !important;
}

.buy-btn:active {
  box-shadow: 0 5px #666;
  transform: translateY(2px);
}

.product-form-wrapper {
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}

.download_zip {
  text-decoration: none;
  color: #fff;
}

.download_zip:hover {
  text-decoration: none;
  color: #fff;
}

.home-btn {
  background-color: #0074c1 !important;
  color: #fff !important;
  font-family: "Open Sans", sans-serif;
}

.home-btn:active {
  background-color: #0074c1;
  box-shadow: 0 5px #666;
  transform: translateY(2px);
}

.home {
  width: 90%;
  margin: 0 auto;
}

.instructions {
  cursor: pointer;
  color: #0074c1;
}

/* Donate */
.donate-btn {
  background-color: #fff !important;
  color: #0074c1 !important;
  font-size: 16px !important;
  font-family: "Lato" !important;
  border-radius: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: 2px solid #0074c1 !important;
  box-shadow: inset 0 0 0 0.25rem rgb(13 110 253 / 20%);
}

.donate-btn:hover {
  background-color: #0074c1 !important;
  color: #fff !important;
  border: none;
}

.donate-btn:active {
  box-shadow: 0 5px #666;
  transform: translateY(2px);
}

.paypal-payment-disable {
  pointer-events: none;
  opacity: 0.4;
}

.on-payment-success {
  margin-top: 1rem;
  text-align: center;
  background: #fff;
  padding: 1px;
}

.payment-success {
  margin-top: 1rem;
  font-size: 20px;
  color: #696969;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}

.success-icon {
  color: #28a745;
  font-size: 30px !important;
}

.thank-you-wrapper {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #0074c1;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  padding-left: 18px;
  padding-right: 18px;
}

/* Not Found */
.not-found-wrapper{ 
  margin: 0 auto !important;
  text-align: center;
  font-family: 'Lato', sans-serif;
  color: #888;
}

.fof{
  margin-bottom: 6rem;
  transform: translateY(50%);
}

/* .fof h1{
  font-size: 36px;
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
} */

@keyframes type{
  from{box-shadow: inset -3px 0px 0px #888;}
  to{box-shadow: inset -3px 0px 0px transparent;}
}

.back-home{
  background-color: #0074c1 !important;
  color: #fff !important;
  padding: 8px !important;
  line-height: 1rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
} 

/* width */
.list-group::-webkit-scrollbar {
  width: 12px;
  cursor:auto;
}

/* Track */
.list-group::-webkit-scrollbar-track {
  box-shadow: transparent; 
  border-radius: 10px;
}
 
/* Handle */
.list-group::-webkit-scrollbar-thumb {
  background-color: #ced4da;
  border-radius: 10px;
}

/* Handle on hover */
.list-group::-webkit-scrollbar-thumb:hover {
  background: #ced4da;
}

/* Media Queries */

@media (min-width: 991.98px) {
  .fixed-height {
    height: 450px;
  }
  .buy-btn,
  .pop-btn {
    font-size: 16px !important;
  }
  .contact-seperator {
    margin-top: 3rem;
  }
  .send-btn {
    padding: 15px !important;
  }
  .blog-post-wrapper {
    border-right: 1px solid #d3d3d3;
  }
  .footer {
    font-size: 16px;
  }
  .blog-heading {
    font-size: 28px;
  }
  .contents > a {
    font-size: 16px;
  }
  .product-type-label{
    position:sticky;
    top: 10px;
  }
  #btn-back-to-top {
    background-color: #0074c1;
  }
  .fof h1{
  font-size: 50px;}
}

@media (min-width: 1399.98px) {
  .fixed-height {
    height: 600px;
  }
  .headings {
    font-size: 24px;
  }
  .sub-heading {
    font-size: 24px;
  }
  .recent-post-heading {
    font-size: 20px;
  }
  .contents {
    font-size: 18px;
  }
  .list-items-wrapper {
    font-size: 18px;
  }
  .list-group-product a {
    font-size: 20px;
  }
  .contact-seperator {
    margin-top: 5rem;
  }
  .continue-reading-blog {
    font-size: 20px;
  }
}